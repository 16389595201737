import React from 'react';
import block from 'bem-cn-lite';
import { Button, Icon } from '@gravity-ui/uikit';
import {FormModal} from '../../../../components/modal';

import {Xmark} from '@gravity-ui/icons';
import './SuccessWithdrawModal.scss';
import successWithdrawIcon from "../../../../assets/success-withdraw.svg";

const b = block('success-withdraw-modal');

interface SuccessWithdrawModalProps {
  open: boolean;
  onClose: () => void;
}

export const SuccessWithdrawModal: React.FC<SuccessWithdrawModalProps> = ({open, onClose}) => {

  return (
    <FormModal
      width={300}
      open={open}
      onClose={onClose}
      actions={
        <div className="action-btns">
          <Button
            onClick={onClose}
            size='l'
            style={{
              width: '100%'
            }}
          >
            Закрыть
            <Icon data={Xmark} size={18} />
          </Button>
        </div>
      }
    >
      <div className={b()}>
        <img src={successWithdrawIcon} alt='success-icon' />
        <span className={b("title")}>Находка выдана</span>
        <p className={b("desc")}>
          Данные о ней можно посмотреть во вкладке «Выданные»
        </p>
      </div>
    </FormModal>
  );
};
