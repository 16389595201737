import React from 'react';
import block from 'bem-cn-lite';
import {Button} from '@gravity-ui/uikit';

import './ActStatusGroupButton.scss';
import { ActStatus } from '../../../../store/api/storage/types/models';

const b = block('act-status-group-btn');

interface ActStatusGroupButtonProps {
status: ActStatus[];
  onChange: (status: ActStatus[]) => void;
}

export const ActStatusGroupButton: React.FC<ActStatusGroupButtonProps> = ({
  status,
  onChange
}) => {
  return (
    <div className={b()}>
      <Button
        view="flat"
        selected={status.includes(ActStatus.DEPOSITING)}
        onClick={() => onChange([ActStatus.DEPOSITING])}
      >
        Зарегистрированные
      </Button>
      <Button
        view="flat"
        selected={status.includes(ActStatus.FILLING) || status.includes(ActStatus.DEPOSITED)}
        
        onClick={() => onChange([ActStatus.FILLING, ActStatus.DEPOSITED])}
      >
        На хранении
      </Button>
      <Button
        view="flat"
        selected={status.includes(ActStatus.WITHDRAWN)}
        onClick={() => onChange([ActStatus.WITHDRAWN])}
      >
        Выданные
      </Button>
      <Button
        view="flat"
        selected={status.includes(ActStatus.UTILIZED)}
        onClick={() => onChange([ActStatus.UTILIZED])}
      >
        Утилизированные
      </Button>
    </div>
  );
};
