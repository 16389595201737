import React from 'react';
import block from 'bem-cn-lite';
import {Button, Icon, Select, TextInput} from '@gravity-ui/uikit';

import './GiveOutFormModal.scss';
import {Xmark} from '@gravity-ui/icons';
import {FormModal} from '../../../../components/modal';
import {InputLabel} from '../../../../components/input-label';
import {clearFormattedPhoneNumber, formatPhoneNumber} from '../../../../utils/phonenumber';
import {PrimaryButton} from '../../../../components/button';
import { WithdrawActPostParams } from '../../../../store/api/storage/types/request';
import { IAct, PaymentType } from '../../../../store/api/storage/types/models';
import { isDigitOrEmpty } from '../../../../utils/validation';

const b = block('give-out-form-modal');

interface GiveOutFormModalProps {
  open: boolean;
  act: IAct | null;
  onClose: () => void;
  onSubmit: (params: WithdrawActPostParams) => void;
}

export const GiveOutFormModal: React.FC<GiveOutFormModalProps> = ({
  open,
  act, 
  onClose, 
  onSubmit
}) => {
  const [disabled, setDisabled] = React.useState(true);
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [clearPhone, setClearPhone] = React.useState("");
  const [paymentType, setPaymentType] = React.useState<string[]>([]);

  const handleOnChangeName = (e: any) => setName(e.target.value);

  const handleOnChangePaymentType = (newValue: string[]) => setPaymentType(newValue);

  const handleOnChangePhone = (e: any) => {
    const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);

    if (isDigitOrEmpty(clearedPhoneNumber)) {
      const formattedPhoneNumber = formatPhoneNumber(clearedPhoneNumber);
      
      if (clearedPhoneNumber.length < 12) {
        setPhone(formattedPhoneNumber);
        setClearPhone(clearedPhoneNumber)
      }
    }
  };

  const clearInput = () => {
    setClearPhone("");
    setPhone("");
    setName("");
    setPaymentType([]);
  }

  const handleOnGiveOutBtnClick = () => {
    if (act) {
      onSubmit({
        actIds: [act.id],
        phoneNumber: '+' + clearPhone,
        name,
        payment: {
          type: paymentType[0],
        }
      });
      clearInput();
    }
  }

  React.useEffect(() => {
    if (
      clearPhone && clearPhone.length === 11 && 
      name && paymentType.at(0)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [clearPhone, name, paymentType])

  return (
    <FormModal
      width={400}
      open={open}
      onClose={() => {
        clearInput();
        onClose();
      }}
      title="Выдача находки"
      description="Внесите данные для выдачи"
      actions={
        <div className="action-btns">
          <Button
            view="flat"
            onClick={() => {
              clearInput();
              onClose();
            }}
            style={{
              color: '#00000080',
            }}
          >
            Отмена
            <Icon data={Xmark} size={16} />
          </Button>
          <PrimaryButton
            onClick={handleOnGiveOutBtnClick}
            disabled={disabled}
          >
            Выдать
          </PrimaryButton>
        </div>
      }
    >
      <div className={b()}>

        <InputLabel labelText="ФИО пассажира">
          <TextInput
            value={name}
            onChange={handleOnChangeName}
            size="l"
            placeholder="Иван Иванович Иванов"
          />
        </InputLabel>

        <InputLabel labelText="Телефон">
          <TextInput
            value={phone}
            onChange={handleOnChangePhone}
            size="l"
            placeholder="+7 (999) 000-00-00"
          />
        </InputLabel>

        <InputLabel labelText="Тип платежа">
          <Select 
            width='max'
            placeholder='Безналичный'
            size="l" 
            value={paymentType} 
            onUpdate={handleOnChangePaymentType}
          >
            <Select.Option value={PaymentType.CARD}>Безналичный</Select.Option>
            <Select.Option value={PaymentType.CASH}>Наличный</Select.Option>
          </Select>
        </InputLabel>
        
      </div>
    </FormModal>
  );
};
