import React from 'react';
import block from 'bem-cn-lite';
import {Button, Icon, Select, TextInput} from '@gravity-ui/uikit';
import { TreeSelect } from 'antd'


import {Sliders} from '@gravity-ui/icons';
import {ChevronDown} from '@gravity-ui/icons';

import './ActFilterPopup.scss';
import { InputLabel } from '../../../../components/input-label';
import { useLazyGetStorageAreasQuery } from '../../../../store/api/storage';
import { Popover } from '@mui/material';
import { IArea } from '../../../../store/api/storage/types/models';
import { ActGetParams } from '../../../../store/api/storage/types/request';

const b = block('act-filter-popup-content');

interface ActFilterPopupProps {
  filter: ActGetParams;
  onSubmit: (params: ActGetParams) => void;
  onReset: () => void;
}

interface TreeSelectItemProps {
	value: string;
	title: string;
	children?: TreeSelectItemProps[];
}


export const ActFilterPopup: React.FC<ActFilterPopupProps> = ({
  filter,
  onSubmit,
  onReset
}) => {

  const [getStorageAreas, {
    data: storageAreas
  }] = useLazyGetStorageAreasQuery();
  const [selectedArea, setSelectedArea] = React.useState<string>(filter.area_id ? filter.area_id : "");
  const handleOnChangeArea = (newArea: string) => {
    setSelectedArea(newArea);
  }

  const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null);
  const handleOnClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => setFilterButtonEl(event.currentTarget);
  const handleCloseFilterPopup = () => setFilterButtonEl(null);

  const openActsFilterPopover = Boolean(filterButtonEl);
  const idActsFilterPopover = openActsFilterPopover ? 'acts-filter-popover' : undefined;

  const [employeeName, setEmployeeName] = React.useState(filter.employee_name ? filter.employee_name : "");
  const handleOnChangeEmployeeName = (e: any) => {
    setEmployeeName(e.target.value);
  };

  const [isInspected, setIsInspected] = React.useState<string[]>([
    filter.is_inspected === true || filter.is_inspected === false 
    ? filter.is_inspected.toString() : ""]
  );
  const handleOnIsInspectedChange = (value: string[]) => {
    setIsInspected(value);
  };

  const areaToTreeItem = (areas: IArea[]): TreeSelectItemProps[] => 
    areas.map(a => ({ 
      title: a.name,
      value: a.id,
      children: areaToTreeItem(a.areas)
    }))
  
  const clear = () => {
    setSelectedArea("");
    setEmployeeName("");
    setIsInspected([""]);
  }
  
  const handleOnApplyClick = () => {
    onSubmit({
      area_id: selectedArea,
      employee_name: employeeName,
      is_inspected: isInspected.at(0) === "true" ? true : isInspected.at(0) === "false" ? false : undefined
    });
    handleCloseFilterPopup();
  }

  const handleOnResetClick = () => {
    onReset();
    clear();
    handleCloseFilterPopup();
  }

  React.useEffect(() => {
    getStorageAreas();
  }, [])

  return (
    <>
      <Button
        onClick={handleOnClickFilter}
        size="l"
        view="flat"
        style={{
          marginLeft: 16,
          backgroundColor: '#0000000D',
        }}
      >
        <Icon data={Sliders} size={18} />
      </Button>
      <Popover
        id={idActsFilterPopover}
        open={openActsFilterPopover}
        anchorEl={filterButtonEl}
        onClose={handleCloseFilterPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={b()}>
          <InputLabel labelText="ФИО сотрудника САБ">
            <TextInput
              value={employeeName}
              onChange={handleOnChangeEmployeeName}
              size="l"
              placeholder="Иван Иванович Иванов"
            />
          </InputLabel>

          <InputLabel labelText="Место обнаружения">
            <TreeSelect
              value={selectedArea}
              onChange={handleOnChangeArea}
              treeData={areaToTreeItem(storageAreas?.result.areas || [])}
              onClick={(e: any) => e.stopPropagation()}
              placeholder={"Кафетерий"}
              treeDefaultExpandAll={false}
              allowClear={true}
              suffixIcon={<Icon data={ChevronDown} size={16} />}
              className={b('tree-select')}
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
                zIndex: 1301
              }}
            />
          </InputLabel>
      
          <InputLabel labelText="Акт осмотра составлен">
            <Select 
              size="l" 
              value={isInspected} 
              onUpdate={handleOnIsInspectedChange}
            >
              <Select.Option value="">-</Select.Option>
              <Select.Option value="true">Да</Select.Option>
              <Select.Option value="false">Нет</Select.Option>
            </Select>
          </InputLabel>
          
          <div className={b('popup-btns')}>
            <Button
              onClick={handleOnApplyClick}
              view="normal"
              style={{
                marginRight: 10,
              }}
            >
              Применить
            </Button>
            <Button 
              view="flat"
              onClick={handleOnResetClick}
            >
              Сбросить
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
