import {Button} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';

import './EmptySidePanel.scss';
import selectFirstAct from '../../../../assets/select-first-act.svg';

const b = block('side-panel-empty');

interface EmptySidePanelProps {
    onSelectFirstActClick: () => void;
}

export const EmptySidePanel: React.FC<EmptySidePanelProps> = ({onSelectFirstActClick}) => {
    return (
        <div className={b()}>
            <div>
                <img src={selectFirstAct} alt="select act" />
            </div>
            <span className={b('acts-empty-title')}>Выберите зарегистрированный акт</span>
            <span className={b('acts-empty-desc')}>
                Чтобы заполнить необходимые данные для передачи на хранение
            </span>
            <div>
                <Button
                    view="normal"
                    size="m"
                    onClick={onSelectFirstActClick}
                    style={{
                        fontSize: 13,
                        marginLeft: 8,
                    }}
                >
                    Открыть первый акт
                </Button>
            </div>
        </div>
    );
};
