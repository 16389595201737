import React from 'react';
import block from 'bem-cn-lite';
import { Spin } from '@gravity-ui/uikit';
import {FormModal} from '../../../../components/modal';

import './TakeActLoadingModal.scss';

const b = block('take-act-loading-modal');

interface TakeActLoadingModalProps {
  open: boolean;
  onClose: () => void;
}

export const TakeActLoadingModal: React.FC<TakeActLoadingModalProps> = ({open, onClose}) => {

  return (
    <FormModal
      width={300}
      open={open}
      onClose={onClose}
    >
      <div className={b()}>
        <Spin size='xl' className={b("spin")} />
        <span className={b("title")}>Добавляем акты...</span>
        <p className={b("desc")}>
          Скоро все появится
        </p>
      </div>
    </FormModal>
  );
};
