import React from 'react';
import block from 'bem-cn-lite';
import {Button, Icon, TextInput} from '@gravity-ui/uikit';

import './RecycleFormModal.scss';
import {Xmark} from '@gravity-ui/icons';
import {FormModal} from '../../../../components/modal';
import {InputLabel} from '../../../../components/input-label';
import {PrimaryButton} from '../../../../components/button';
import { UtilizeActPostParams } from '../../../../store/api/storage/types/request';
import { IAct } from '../../../../store/api/storage/types/models';

const b = block('recycle-form-modal');

interface RecycleFormModalProps {
  open: boolean;
  act: IAct | null;
  onClose: () => void;
  onSubmit: (params: UtilizeActPostParams) => void;
}

export const RecycleFormModal: React.FC<RecycleFormModalProps> = ({open, act, onClose, onSubmit}) => {
  const [disabled, setDisabled] = React.useState(true);
  const [employeeName1, setEmployeeName1] = React.useState('');
  const [employeeName2, setEmployeeName2] = React.useState('');
  const [employeeName3, setEmployeeName3] = React.useState('');

  const handleOnChangeEmployeeName1 = (e: any) => setEmployeeName1(e.target.value);
  const handleOnChangeEmployeeName2 = (e: any) => setEmployeeName2(e.target.value);
  const handleOnChangeEmployeeName3 = (e: any) => setEmployeeName3(e.target.value);

  const handleOnRecycleBtnClick = () => {
    if (act) {
      onSubmit({
        actIds: [act.id],
        chairmanName: employeeName1,
        employeeName: employeeName2,
        secondEmployeeName: employeeName3,
      });
      clearInput();
    }
  }

  const clearInput = () => {
    setEmployeeName1("");
    setEmployeeName2("");
    setEmployeeName3("");
  }

  React.useEffect(() => {
    if (
      employeeName1 && employeeName2 && employeeName3
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [employeeName1, employeeName2, employeeName3])
 
  return (
    <FormModal
      width={400}
      open={open}
      onClose={() => {
        clearInput()
        onClose();
      }}
      title="Утилизация находки"
      description="Внесите данные для утилизации"
      actions={
        <div className="action-btns">
          <Button
            view="flat"
            onClick={() => {
              clearInput()
              onClose();
            }}
            style={{
              color: '#00000080',
            }}
          >
            Отмена
            <Icon data={Xmark} size={16} />
          </Button>
          <PrimaryButton
            onClick={handleOnRecycleBtnClick}
            disabled={disabled}
          >
            Утилизировать
          </PrimaryButton>
        </div>
      }
    >
      <div className={b()}>
        <InputLabel labelText="Председатель комиссии (сотрудник САБ)">
          <TextInput 
            size="l" 
            placeholder="Иван Иванович Иванов" 
            value={employeeName1}
            onChange={handleOnChangeEmployeeName1}
          />
        </InputLabel>
        <InputLabel labelText="Сотрудник КХ">
          <TextInput 
            size="l" 
            placeholder="Иван Иванович Иванов" 
            value={employeeName2}
            onChange={handleOnChangeEmployeeName2}
          />
        </InputLabel>
        <InputLabel labelText="Сотрудник КХ 2">
          <TextInput 
            size="l" 
            placeholder="Иван Иванович Иванов" 
            value={employeeName3}
            onChange={handleOnChangeEmployeeName3}
          />
        </InputLabel>
      </div>
    </FormModal>
  );
};
