import React from 'react';
import {Button, Divider, Icon, Select, Text, TextArea, TextInput} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';

import './style.scss';
import {InputLabel} from '../../../input-label';
import {PrimaryButton} from '../../../button';
import {GroupButtons} from '../../../group-buttons';
import {ActStatus, IAct} from '../../../../store/api/storage/types/models';
import { useLazyGetCategoriesQuery } from '../../../../store/api/storage';
import { isDigitOrEmpty } from '../../../../utils/validation';
import { FillActPostParams } from '../../../../store/api/storage/types/request';
import { Collapse, Stack } from '@mui/material';
import { DateTime } from '@gravity-ui/date-utils';

import {ChevronDown, ChevronUp} from '@gravity-ui/icons';
import { formatPhoneNumber } from '../../../../utils/phonenumber';

const b = block('side-panel-form');

interface SavedStatusFormProps {
  act: IAct;
  storageActDate: DateTime | null;
  onEnterDataClick: (params: FillActPostParams) => void;
  onGiveOutFindClick: () => void;
  onRecycleFindClick: () => void;
}

export const EnterActDataForm: React.FC<SavedStatusFormProps> = ({
  act,
  storageActDate,
  onEnterDataClick,
  onGiveOutFindClick,
  onRecycleFindClick,
}) => {
  const [getCategories, {
    data: getCategoriesResponse,
    ...getCategoriesRequestInfo
  }] = useLazyGetCategoriesQuery();

  const [openActIntoCollapse, setOpenActIntoCollapse] = React.useState(true);
  const toggleOpenActCollapseInfo = () => setOpenActIntoCollapse(!openActIntoCollapse);

  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [category, setCategory] = React.useState<string[] | undefined>(undefined);
  const handleOnUpdateCategory = (category: string[]) => {
    setCategory(category);
    setSubCategoryId([]);
  }

  const [subCategory, setSubCategory] = React.useState<string>("");
  const [subCategoryId, setSubCategoryId] = React.useState<string[] | undefined>(undefined);
  const handleOnUpdateSubCategory = (subCategory: string[]) => setSubCategoryId(subCategory)
  const subCategories = React.useMemo(() => {
    return category 
      ? (getCategoriesResponse?.result.categories || [])
        .find(c => c.name === category.at(0))?.types || []
      : [];
  }, [category])

  const [description, setDescription] = React.useState("");
  const handleOnChangeDescription = (e: any) => setDescription(e.target.value);

  const [tagNumber, setTagNumber] = React.useState("");
  const handleOnChangeTagNumber = (e: any) => setTagNumber(e.target.value);

  const [shelveNumber, setShelveNumber] = React.useState("");
  const handleOnChangeShelveNumber = (e: any) => setShelveNumber(e.target.value);

  const [weight, setWeight] = React.useState("");
  const handleOnChangeWeight = (e: any) => {
    if (isDigitOrEmpty(e.target.value)) {
      setWeight(e.target.value);
    }
  }

  const handleOnEnterDataClick = () => {
    if (!storageActDate) {
      return;
    }

    onEnterDataClick({
      actId: act.id,
      employeeName: act.employeeName,
      itemTypeId: subCategoryId?.at(0) || "" ,
      storageDescription: description,
      storageActDate: storageActDate.toISOString(),
      weight: weight ? parseInt(weight) : 0,
      shelveNumber,
      tagNumber,
    })
  }

  React.useEffect(() => {
    getCategories();
  }, [])

  React.useEffect(() => {
    if (subCategoryId?.at(0) && description && 
      weight && shelveNumber && tagNumber) 
    {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [subCategoryId, description, weight, shelveNumber, tagNumber])

  React.useEffect(() => {
    setDescription("");
    setTagNumber("");
    setShelveNumber("");
    setWeight("");
    setCategory([]);
    setSubCategory("");
    setSubCategoryId(undefined);
    
    if (
      getCategoriesRequestInfo.isSuccess && 
      act.status !== ActStatus.FILLING
    ) {
      const actCategory = getCategoriesResponse?.result.categories.find(c => c.types.map(t => t.id).includes(act.itemTypeId || ""));
      if (actCategory) {
        setCategory([actCategory.name]);
        const actSubCategory = actCategory.types.find(t => t.id === act.itemTypeId);
        if (actSubCategory) {
          setSubCategory(actSubCategory.name);
        }
      }
    }
  }, [getCategoriesRequestInfo.isSuccess, act])

  return (
    <>
      <div className={b()}>
        <span className={b('form-title')} onClick={toggleOpenActCollapseInfo}>
          Данные о находке
          <Icon data={openActIntoCollapse ? ChevronUp : ChevronDown} size={18}/>
        </span>
        <Collapse in={openActIntoCollapse} timeout='auto' unmountOnExit>
          <InputLabel labelText="Категория вещей">
            {act.status === ActStatus.FILLING
              ? <Select 
                  size="l" 
                  placeholder="Выберите категорию"
                  value={category}
                  onUpdate={handleOnUpdateCategory}
                >
                  {
                    (getCategoriesResponse?.result.categories || []).map(c =>
                      <Select.Option value={c.name}>{c.name}</Select.Option>
                    )
                  }
                </Select>
              : <Text variant='body-2'>
                  {category}
                </Text>
            }
          </InputLabel>

          {act.status !== ActStatus.FILLING
            ? <Divider style={{ marginBottom: 10 }} />
            : null
          }

          <InputLabel labelText="Тип багажа">
            {act.status === ActStatus.FILLING
              ? <Select 
                  size="l" 
                  placeholder="Выберите тип багажа"
                  disabled={!category}
                  value={subCategoryId}
                  onUpdate={handleOnUpdateSubCategory}
                >
                  {
                    subCategories.map(subc =>
                      <Select.Option value={subc.id}>{subc.name}</Select.Option>
                    )
                  }
                </Select>
              : <Text variant='body-2'>
                  {subCategory}
                </Text>
            }
          </InputLabel>

          {act.status !== ActStatus.FILLING
            ? <Divider style={{ marginBottom: 8 }} />
            : null
          }

          <InputLabel labelText="Описание">
            {act.status === ActStatus.FILLING
              ? <TextArea
                  value={description}
                  onChange={handleOnChangeDescription} 
                  minRows={6} 
                  maxRows={10} 
                  controlProps={{
                    maxLength: 255
                  }}
                  placeholder="Опишите находку..." 
                />
              : <Text variant='body-2'>
                  {act.storageDescription}
                </Text>
            }
            
          </InputLabel>

          {act.status !== ActStatus.FILLING
            ? <Divider style={{ marginBottom: 8 }} />
            : null
          }

          <Stack 
            direction='row' 
            alignItems='center' 
            spacing={act.status === ActStatus.FILLING ? 1 : 13}
          >
            <InputLabel labelText="Полка">
              {act.status === ActStatus.FILLING
                ? <TextInput 
                    size="l"
                    value={shelveNumber}
                    onChange={handleOnChangeShelveNumber} 
                  />
                : <Text variant='body-2'>
                    {act.shelveNumber}
                  </Text>
              }
            </InputLabel>
            <InputLabel labelText="Бирка">
              {act.status === ActStatus.FILLING
                ? <TextInput 
                    size="l"
                    value={tagNumber}
                    onChange={handleOnChangeTagNumber} 
                  />
                : <Text variant='body-2'>
                    {act.tagNumber}
                  </Text>
              }
            </InputLabel>
            <InputLabel labelText="Вес (г)">
              {act.status === ActStatus.FILLING
                ? <TextInput 
                    size="l"
                    value={weight}
                    onChange={handleOnChangeWeight} 
                  />
                : <Text variant='body-2'>
                    {act.weight}
                  </Text>
              }
            </InputLabel>
          </Stack>
          {act.status !== ActStatus.FILLING
            ? <Divider style={{ marginBottom: 24 }} />
            : null
          }
        </Collapse>

        {act.status === ActStatus.WITHDRAWN
          ? <WithdrawInfo act={act} />
          : null
        }

        {act.status === ActStatus.UTILIZED
          ? <UtilizeInfo act={act} />
          : null
        }
        

        
      </div>
      {act.status === ActStatus.FILLING 
        ? <PrimaryButton
            onClick={handleOnEnterDataClick}
            disabled={disabledBtn}
            size="xl"
            style={{
              width: '100%',
            }}
          >
            Внести данные
          </PrimaryButton>
        : null 
       }
       
       {act.status === ActStatus.DEPOSITED
        ? <GroupButtons>
            <Button
              onClick={onGiveOutFindClick}
              size="xl"
              view="outlined-success"
              style={{width: '100%'}}
            >
              Выдать находку
            </Button>
            <Button
              onClick={onRecycleFindClick}
              size="xl"
              view="outlined-warning"
              style={{width: '100%'}}
            >
              Утилизировать
            </Button>
          </GroupButtons>
        : null
       }
    </>
  );
};

const WithdrawInfo: React.FC<{ act: IAct }> = ({ act }) => {
  const [openCollapse, setOpenCollapse] = React.useState(true);
  const toggleOpenCollapse = () => setOpenCollapse(!openCollapse);

  return (
    <>
      <span className={b('form-title')} onClick={toggleOpenCollapse}>
        Данные о выдаче
        <Icon data={openCollapse ? ChevronUp : ChevronDown} size={18}/>
      </span>
      <Collapse in={openCollapse} timeout='auto' unmountOnExit>

        <InputLabel labelText="ФИО пассажира">
          <Text variant='body-2'>
          {act.withdrawAct?.name}
          </Text>
        </InputLabel>
        <Divider style={{ marginBottom: 10 }} />

        <InputLabel labelText="Телефон">
          <Text variant='body-2'>
            {formatPhoneNumber(act.withdrawAct?.phoneNumber || "")}
          </Text>
        </InputLabel>
        <Divider style={{ marginBottom: 10 }} />

        <InputLabel labelText="ФИО сотрудника КХ">
          <Text variant='body-2'>
            {act.withdrawAct?.storageEmployeeName}
          </Text>
        </InputLabel>
        <Divider style={{ marginBottom: 10 }} />

      </Collapse>
    </>
  );
}

const UtilizeInfo: React.FC<{ act: IAct }> = ({ act }) => {
  const [openCollapse, setOpenCollapse] = React.useState(true);
  const toggleOpenCollapse = () => setOpenCollapse(!openCollapse);

  return (
    <>
      <span className={b('form-title')} onClick={toggleOpenCollapse}>
        Данные об утилизации
        <Icon data={openCollapse ? ChevronUp : ChevronDown} size={18}/>
      </span>
      <Collapse in={openCollapse} timeout='auto' unmountOnExit>

        <InputLabel labelText="Председатель комиссии (сотрудник САБ)">
          <Text variant='body-2'>
          {act.utilizeAct?.chairmanName}
          </Text>
        </InputLabel>
        <Divider style={{ marginBottom: 10 }} />

        <InputLabel labelText="Сотрудник КХ">
          <Text variant='body-2'>
            {act.utilizeAct?.employeeName}
          </Text>
        </InputLabel>
        <Divider style={{ marginBottom: 10 }} />

        <InputLabel labelText="Сотрудник КХ 2">
          <Text variant='body-2'>
          {act.utilizeAct?.secondEmployeeName}
          </Text>
        </InputLabel>
        <Divider style={{ marginBottom: 10 }} />

      </Collapse>
    </>
  );
}