import React from 'react';
import block from 'bem-cn-lite';

import './Main.scss';
import {Layout} from '../../components/layout';
import {Icon, useToaster} from '@gravity-ui/uikit';

import {useBindNavigator} from '../../hooks/use-navigator';
import {useLocationParams} from '../../hooks/use-params';

import emptyActs from '../../assets/empty-acts.png';
import {PrimaryButton} from '../../components/button';
import {ActCard} from '../../components/act-card';
import {ActSidePanel} from '../../components/act-side-panel';
import {GiveOutFormModal} from './ui/GiveOutModal/GiveOutFormModal';
import {RecycleFormModal} from './ui/RecycleModal';
import {ActCounter} from '../../components/act-counter';
import {ActStatus, IAct} from '../../store/api/storage/types/models';
import {ErrorToaster, SuccessToaster} from '../../components/toaster/modal';
import {CircleCheckFill, } from '@gravity-ui/icons';
import {CircleXmarkFill} from '@gravity-ui/icons';
import {TakeActsFormModal} from './ui/TakeActsModal';
import {
  useDeclineActMutation,
  useDepositActsMutation,
  useFillActMutation,
  useLazyGetActByIdQuery,
  useLazyGetActsQuery,
  useReceiveActMutation,
  useUtilizeActMutation,
  useWithdrawActMutation,
} from '../../store/api/storage';
import {ActStatusGroupButton} from './ui/ActStatusGroupButton';
import {ActFilterPopup} from './ui/ActFilterPopup';
import {ActGetParams, FillActPostParams, UtilizeActPostParams, WithdrawActPostParams} from '../../store/api/storage/types/request';
import {SuccessWithdrawModal} from './ui/SuccessWithdrawModal';
import {ActList} from './ui/ActList';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { TakeActLoadingModal } from './ui/TakeActLoadingModal';
import { SuccessTakeActsModal } from './ui/SuccessTakeActsModal';
import { SuccessUtilizeModal } from './ui/SuccessUtilizeModal';

const b = block('main-page');

const ACT_LIST_OFFSET = 10;

export const MainPage: React.FC = () => {
  const {add} = useToaster();
  const {addToNavigateBar, clearNavigatorBar} = useBindNavigator<any>();
  const {params} = useLocationParams<any>(window.location.search);

  const [selectedActStatus, setSelectedActStatus] = React.useState<ActStatus[]>(
    params.status ? Array.isArray(params.status) ? params.status : [params.status] : [ActStatus.DEPOSITING],
  );
  const handleChangeSelectedActStatus = async (actStatus: ActStatus[]) => {
    setSelectedActId('');
    setSelectedAct(null);
    setSelectedActStatus(actStatus);
    setActRequestOffset(ACT_LIST_OFFSET);
    
    addToNavigateBar({
      ...params,
      actId: null,
      status: actStatus,
    });
    const r = await getActs({
      ...params, 
      status: actStatus
    });
    setActs(r.data?.result.acts || []);
    setStopScrollActList(false);
  };

  const [acts, setActs] = React.useState<IAct[]>([]);
  const actsByStatus = React.useMemo(() => 
    acts.filter(act => selectedActStatus.includes(act.status)),
    [acts, selectedActStatus]
  )

  const [getActs, {data: getActsData, ...getActsRequestInfo}] = useLazyGetActsQuery();
  const changeActsStatus = (actIds: string[], newStatus: ActStatus) => {
    
    const exceptedActs = acts.filter(act => !actIds.includes(act.id) && selectedActStatus.includes(act.status));
    const updatedActs = acts.map(act => {
      if (actIds.includes(act.id)) {
        return ({...act, status: newStatus});
      } else {
        return {...act};
      }
    });
    if (newStatus === ActStatus.WITHDRAWN || newStatus === ActStatus.UTILIZED) {
      console.log(exceptedActs)
      if (exceptedActs.length > 0) {
        handleOnClickActCard(exceptedActs[0])
      }
    }
    setActs(updatedActs);
  }

  const [getActById, {
    data: getActByIdResponse, 
    ...getActByIdRequestInfo
  }] = useLazyGetActByIdQuery();

  const [takeActsBySendingCode, {
    ...takeActsRequestInfo
  }] = useDepositActsMutation();

  const [
    receiveAct, {
      data: receiveActResponse,
      ...receiveActRequestInfo
    },
  ] = useReceiveActMutation();

  const [
    declineAct, {
      data: declineActResponse,
      ...declineActRequestInfo
    },
  ] = useDeclineActMutation();

  const [
    fillAct, {
      data: fillActResponse,
      ...fillActRequestInfo
    },
  ] = useFillActMutation();

  const [withdrawAct, {
    data: withdrawActResponse,
    ...withdrawActRequestInfo
  }] = useWithdrawActMutation();

  const [utilizeAct, {
    data: utilizeActResponse,
    ...utilizeActRequestInfo
  }] = useUtilizeActMutation();

  const handleOnApplyFilter = (newParams: ActGetParams) => {
    addToNavigateBar({
      ...params,
      ...newParams
    });

    getActs({ ...params, ...newParams}).then((r) => {
      setActs([
        ...(r.data?.result.acts || []),
      ]);
    });
  };

  const handleOnResetFilter = () => {
    clearNavigatorBar({
      status: params.status
    });

    getActs({ status: params.status}).then((r) => {
      setActs([
        ...(r.data?.result.acts || []),
      ]);
    });
  };

  const [selectedAct, setSelectedAct] = React.useState<IAct | null>(null);
  const [selectedActId, setSelectedActId] = React.useState<string>('');
  const handleOnClickActCard = (act: IAct) => {
    setSelectedAct(act);
    setSelectedActId(act.id);
    addToNavigateBar({
      ...params,
      actId: act.id,
    });
    getActById(act.id);
  };

  const [selectedActsId, setSelectedActsId] = React.useState<string[]>([]);
  const handleOnSelectAct = (actId: string) => {
    const actIdIndex = selectedActsId.findIndex((_actId) => _actId === actId);
    const newSelectedActsId = [...selectedActsId];
    if (actIdIndex !== -1) {
      newSelectedActsId.splice(actIdIndex, 1);
    } else {
      newSelectedActsId.push(actId);
    }
    setSelectedActsId(newSelectedActsId);
  };
  const handleOnCloseActCounter = () => setSelectedActsId([]);
  const handleOnAllSelectActCounter = () => setSelectedActsId(actsByStatus.map((act) => act.id));

  const handleEnterDataClick = (params: FillActPostParams) => fillAct(params);

  const [openGiveOutFindModal, setOpenGiveOutFindModal] = React.useState(false);
  const closeGiveOutFindModal = () => setOpenGiveOutFindModal(false);
  const handleGiveOutFindClick = () => {
    setOpenGiveOutFindModal(true);
  };
  const handleOnGiveOutFormModalSubmit = (params: WithdrawActPostParams) => {
    withdrawAct(params);
  };

  const [openRecycleFindModal, setOpenRecycleFindModal] = React.useState(false);
  const closeRecycleFindModal = () => setOpenRecycleFindModal(false);
  const handleRecycleFindClick = () => {
    setOpenRecycleFindModal(true);
  };
  const handleOnRecycleFormModalSubmit = (params: UtilizeActPostParams) => {
    utilizeAct(params);
  };

  const [openTakeActsPopup, setOpenTakeActsPopup] = React.useState(false);
  const handleOnClickTakeActs = () => setOpenTakeActsPopup(true);
  const closeTakeActsPopup = () => setOpenTakeActsPopup(false);
  const handleOnTakeActsFormModalSubmit = (code: string) => {
    takeActsBySendingCode({code});
  };

  const [openSuccessWithdrawModal, setOpenSuccessWithdrawModal] = React.useState(false);
  const closeSuccessWithdrawModal = () => setOpenSuccessWithdrawModal(false);

  const [openSuccessUtilizeModal, setOpenSuccessUtilizeModal] = React.useState(false);
  const closeSuccessUtilizeModal = () => setOpenSuccessUtilizeModal(false);

  const [openTakeActsLoadingModal, setOpenTakeActsLoadingModal] = React.useState(false);
  const closeTakeActsLoadingModal = () => setOpenTakeActsLoadingModal(false);

  const [openSuccessTakeActsLoadingModal, setOpenSuccessTakeActsLoadingModal] = React.useState(false);
  const closeSuccessTakeActsLoadingModal = () => setOpenSuccessTakeActsLoadingModal(false);

  const [actRequestOffset, setActRequestOffset] = React.useState<number>(ACT_LIST_OFFSET);
  const [stopScrollActList, setStopScrollActList] = React.useState<boolean>(false);
  const [dataTrigger, setDataTrigger] = React.useState("");

  const handleOnScrollActList = async () => {
    if (stopScrollActList) return;

    setDataTrigger("scroll");
    try {
      const r = await getActs({ 
        ...params, 
        offset: actRequestOffset 
      })
      
      if (r.status === QueryStatus.fulfilled && r.data) {
        setActs([...acts, ...r.data.result.acts])
        if (r.data.result.acts.length === ACT_LIST_OFFSET) {
          setActRequestOffset(actRequestOffset + ACT_LIST_OFFSET);
        } else {
          setStopScrollActList(true);
        }
      } 
    } catch {

    }
    setDataTrigger("");
  }

  React.useEffect(() => {
    setActs([]);

    getActs({ 
      ...params,
      status: params.status || selectedActStatus
    }).then((r) => {
      setActs([
        ...acts, 
        ...(r.data?.result.acts || []),
      ]);
    });

    if (params.actId) {
      setSelectedActId(params.actId);
      getActById(params.actId);
    }

    addToNavigateBar({
      ...params
    });
  }, []);

  React.useEffect(() => {
    if (getActByIdRequestInfo.status === QueryStatus.fulfilled && getActByIdResponse) {
      setSelectedAct(getActByIdResponse.result);
    }
  }, [getActByIdRequestInfo.status, getActByIdResponse]);

  React.useEffect(() => {
    if (takeActsRequestInfo.isSuccess) {
      setOpenSuccessTakeActsLoadingModal(true);
      getActs({}).then((r) => {
        setActs([...(r.data?.result.acts || [])]);
      });
    }
  }, [takeActsRequestInfo.isSuccess]);

  React.useEffect(() => {
    if (takeActsRequestInfo.isError) {
      setOpenSuccessTakeActsLoadingModal(false);
      add({
        name: 'error-send-code',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка при отправке кода`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: takeActsRequestInfo.error.error.message,
      });
    }
  }, [takeActsRequestInfo.isError]);

  React.useEffect(() => {
    closeTakeActsPopup()
    setOpenTakeActsLoadingModal(takeActsRequestInfo.isLoading);
  }, [takeActsRequestInfo.isLoading]);

  React.useEffect(() => {
    if (receiveActRequestInfo.status === QueryStatus.fulfilled) {
      const receivedActIds = (receiveActResponse?.result.actIds || []);
      changeActsStatus(receivedActIds, ActStatus.FILLING);

      let toastTitle = "";
      if (receivedActIds.length > 1) {
        toastTitle = `Принято ${receivedActIds.length} актов`;
        setSelectedActsId([]);
      } else {
        const act = acts.find(a => receivedActIds.includes(a.id));
        toastTitle = `Акт №${act?.number} принят`;
      }

      add({
        name: 'receive-act',
        autoHiding: 3000,
        isClosable: true,
        title: toastTitle,
        renderIcon: () => <Icon className="success-green" data={CircleCheckFill} size={18} />,
        content: <SuccessToaster />,
      });
    }
  }, [receiveActRequestInfo.status, receiveActResponse]);

  React.useEffect(() => {
    if (declineActRequestInfo.status === QueryStatus.fulfilled) {
      const declinedActIds = (declineActResponse?.result.actIds || []);
      changeActsStatus(declinedActIds, ActStatus.DELETED);

      let toastTitle = "";
      if (declinedActIds.length > 1) {
        toastTitle = `Отклонено ${declinedActIds.length} актов`;
        setSelectedActsId([]);
      } else {
        const act = acts.find(a => declinedActIds.includes(a.id));
        toastTitle = `Акт №${act?.number} отклонен`;
      }

      add({
        name: 'decline-act',
        autoHiding: 3000,
        isClosable: true,
        title: toastTitle,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        content: <ErrorToaster />,
      });
    }
  }, [declineActRequestInfo.status, declineActResponse]);

  React.useEffect(() => {
    if (fillActRequestInfo.status === QueryStatus.fulfilled) {
      changeActsStatus([fillActResponse?.result.actId || ""], ActStatus.DEPOSITED);
      getActById(fillActResponse?.result.actId || "");
      add({
        name: 'fill-act',
        autoHiding: 3000,
        isClosable: true,
        title: "Данные успешно внесены",
        renderIcon: () => <Icon className="success-green" data={CircleCheckFill} size={18} />,
        content: <SuccessToaster />,
      });
    }
  }, [fillActRequestInfo.status, fillActResponse]);

  React.useEffect(() => {
    if (utilizeActRequestInfo.status === QueryStatus.fulfilled) {
      changeActsStatus([utilizeActResponse?.result.actId || ""], ActStatus.UTILIZED);
      closeRecycleFindModal();
      setOpenSuccessUtilizeModal(true);
    }
  }, [utilizeActRequestInfo.status, utilizeActResponse]);

  React.useEffect(() => {
    if (withdrawActRequestInfo.status === QueryStatus.fulfilled) {
      changeActsStatus([withdrawActResponse?.result.actId || ""], ActStatus.WITHDRAWN);
      closeGiveOutFindModal();
      setOpenSuccessWithdrawModal(true);
    }
  }, [withdrawActRequestInfo.status, withdrawActResponse]);

  React.useEffect(() => {
    if (
      fillActRequestInfo.isError ||
      withdrawActRequestInfo.isError ||
      utilizeActRequestInfo.isError ||
      receiveActRequestInfo.isError ||
      declineActRequestInfo.isError ||
      getActsRequestInfo.isError
    ) {

      // @ts-ignore
      const errorMessage = fillActRequestInfo?.error?.error.message ||
      // @ts-ignore
      withdrawActRequestInfo?.error?.error.message ||
      // @ts-ignore
      utilizeActRequestInfo?.error?.error.message ||
      // @ts-ignore
      receiveActRequestInfo?.error?.error.message ||
      // @ts-ignore
      declineActRequestInfo?.error?.error.message ||
      // @ts-ignore
      getActsRequestInfo?.error?.error.message || ""

      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Невозможно выполнить запрос`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        content: errorMessage,
      });
    }
  }, [
    fillActRequestInfo.isError, 
    withdrawActRequestInfo.isError, 
    utilizeActRequestInfo.isError,
    receiveActRequestInfo.isError,
    declineActRequestInfo.isError,
    getActsRequestInfo.isError
  ]);

  return (
    <Layout>
      <div className={b()}>
        <div className={b('container-flex')}>
          <div className={b('container-flex-column')}>
            <div className={b('acts-nav')}>
              <span className={b('title')}>Акты</span>

              <div className={b('group-btn')}>
                <ActStatusGroupButton
                  status={selectedActStatus}
                  onChange={handleChangeSelectedActStatus}
                />
                <ActFilterPopup
                  filter={params} 
                  onReset={handleOnResetFilter}
                  onSubmit={handleOnApplyFilter} 
                />
              </div>
            </div>

            <ActList
              acts={actsByStatus}
              isLoading={getActsRequestInfo.isFetching && dataTrigger !== "scroll"}
              isScrollLoading={getActsRequestInfo.isFetching && dataTrigger === "scroll"}
              onScroll={handleOnScrollActList}
              renderEmptyList={() => (
                selectedActStatus.includes(ActStatus.DEPOSITING)
                ? <TakeActs onClickTakeActs={handleOnClickTakeActs}/>
                : <NoActs />
              )}
              renderItem={(act: IAct) => (
                <ActCard
                  key={act.id}
                  act={act}
                  onClick={() => handleOnClickActCard(act)}
                  focused={act.id === selectedActId}
                  selected={selectedActsId.includes(act.id)}
                  onSelect={handleOnSelectAct}
                  onReceiveAct={() => receiveAct({ actIds: [act.id] })}
                  onDeclineAct={() => declineAct({ actIds: [act.id] })}
                />
              )}
            />
          </div>

          {actsByStatus.length !== 0 && 
          !selectedActStatus.includes(ActStatus.DEPOSITING) ? (
            <ActSidePanel
              act={selectedAct}
              onSelectFirstActClick={() => handleOnClickActCard(acts[0])}
              onEnterDataClick={handleEnterDataClick}
              onGiveOutFindClick={handleGiveOutFindClick}
              onRecycleFindClick={handleRecycleFindClick}
            />
          ) : null}
        </div>
      </div>

      {selectedActsId.length !== 0 ? (
        <ActCounter
          actIds={selectedActsId}
          onApply={() => receiveAct({ actIds: selectedActsId })}
          onReject={() => declineAct({ actIds: selectedActsId })}
          onAllSelect={handleOnAllSelectActCounter}
          onClose={handleOnCloseActCounter}
        />
      ) : null}

      <GiveOutFormModal
        open={openGiveOutFindModal}
        act={selectedAct}
        onClose={closeGiveOutFindModal}
        onSubmit={handleOnGiveOutFormModalSubmit}
      />

      <RecycleFormModal
        open={openRecycleFindModal}
        act={selectedAct}
        onClose={closeRecycleFindModal}
        onSubmit={handleOnRecycleFormModalSubmit}
      />

      <TakeActsFormModal
        open={openTakeActsPopup}
        onClose={closeTakeActsPopup}
        onSubmit={handleOnTakeActsFormModalSubmit}
      />

      <TakeActLoadingModal 
        open={openTakeActsLoadingModal}
        onClose={closeTakeActsLoadingModal}
      />

      <SuccessTakeActsModal
        open={openSuccessTakeActsLoadingModal}
        onClose={closeSuccessTakeActsLoadingModal}
      />

      <SuccessWithdrawModal 
        open={openSuccessWithdrawModal} 
        onClose={closeSuccessWithdrawModal} 
      />

      <SuccessUtilizeModal 
        open={openSuccessUtilizeModal} 
        onClose={closeSuccessUtilizeModal} 
      />

    </Layout>
  );
};

interface EmptyActBoxProps {
  onClickTakeActs: () => void;
}

const TakeActs: React.FC<EmptyActBoxProps> = ({onClickTakeActs}) => {
  return (
    <div className={b('acts-empty')}>
      <div>
        <img src={emptyActs} alt="empty acts" />
      </div>
      <span className={b('acts-empty-title')}>Пока что нет ни одного акта</span>
      <span className={b('acts-empty-desc')}>
        Сверьте и примите акты у сотрудника САБ, чтобы увидеть их для регистрации или отклонения
      </span>
      <div>
        <PrimaryButton size="l" style={{fontSize: 13}} onClick={onClickTakeActs}>
          Принять акты
        </PrimaryButton>
      </div>
    </div>
  );
};

const NoActs: React.FC = () => {
  return (
    <div className={b('acts-empty')}>
      <div>
        <img src={emptyActs} alt="empty acts" />
      </div>
      <span className={b('acts-empty-title')}>Пока что нет ни одного акта</span>
      <span className={b('acts-empty-desc')}>
        В данном статусе актов нет
      </span>
    </div>
  );
};
