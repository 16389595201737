import React from 'react';
import { Button, Icon, Popup } from '@gravity-ui/uikit';
import block from 'bem-cn-lite';

import {Pencil} from '@gravity-ui/icons';
import './EditDatePopup.scss';
import { InputLabel } from '../../../input-label';
import { Calendar, DateField } from '@gravity-ui/date-components';
import { DateTime } from '@gravity-ui/date-utils';

const b = block('edit-date-popup');

interface EditDatePopupProps {
	actDate: DateTime | null;
	onDateEdit: (newDate: DateTime | null) => void;
}

export const EditDatePopup: React.FC<EditDatePopupProps> = ({
	actDate,
	onDateEdit
}) => {

	const [date, setDate] = React.useState<DateTime | null>(actDate);
	const editDateButtonRef = React.useRef(null);
  const [openEditDatePopup, setOpenEditDatePopup] = React.useState(false);

  const handleOnClickEditDate = () => setOpenEditDatePopup(true);
  const handleCloseEditDatePopup = () => setOpenEditDatePopup(false);

	const handleOnDateChange = (newDate: DateTime | null) => setDate(newDate);
	const handleOnTimeChange = (newTime: DateTime | null) => setDate(newTime);

	const handleOnApplyEditClick = () => {
		onDateEdit(date);
		handleCloseEditDatePopup();
	}

	React.useEffect(() => {
		setDate(actDate);
	}, [actDate])

	return (
		<>
			<Button
				view="flat"
				ref={editDateButtonRef}
				onClick={handleOnClickEditDate}
				className="datetime-edit-btn"
			>
				<Icon data={Pencil} size={16} />
			</Button>
			<Popup
				anchorRef={editDateButtonRef}
				open={openEditDatePopup}
				placement="bottom-end"
				onClose={handleCloseEditDatePopup}
			>
				<div className={b()}>
					<Calendar 
						size="l" 
						value={date} 
						onUpdate={handleOnDateChange}
					/>
					<InputLabel labelText="Время">
						<DateField 
							size="l"
							value={date}
							onUpdate={handleOnTimeChange}
							format='HH:mm'
							placeholder='HH:mm'
							style={{ width: '100%' }}
						/>
					</InputLabel>
					<Button
						onClick={handleOnApplyEditClick}
						view="normal"
						style={{
							width: '100%',
						}}
					>
						Применить
					</Button>
				</div>
			</Popup>
		</>
	);
};
