import React from 'react';
import block from 'bem-cn-lite';
import {PinInput} from '@gravity-ui/uikit';

import './TakeActsFormModal.scss';
import {FormModal} from '../../../../components/modal';
import {PrimaryButton} from '../../../../components/button';

const b = block('take-acts-form-modal');

interface TakeActsFormModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (code: string) => void;
}

export const TakeActsFormModal: React.FC<TakeActsFormModalProps> = ({open, onClose, onSubmit}) => {
  const [code, setCode] = React.useState<string[]>([]);

  const handleOnChangeCode = (newCode: string[]) => {
    setCode(newCode);
  };

  const handleOnTakeDataClick = () => {
    onSubmit(code.join(''));
    setCode([]);
  }

  const isCodeCompleted = () => code.filter(c => c).length === 6;

  return (
    <FormModal
      width={500}
      open={open}
      onClose={() => {
        setCode([]);
        onClose();
      }}
      title="Принять акты у сотрудника САБ"
      description="Сверьте коды с сотрудником САБ и примите акты, чтобы загрузить их в систему"
      actions={
        <div className="action-btns">
          <PrimaryButton
            disabled={!isCodeCompleted()}
            onClick={handleOnTakeDataClick}
            size='xl'
            style={{
              width: '100%'
            }}
          >
            Принять акты
          </PrimaryButton>
        </div>
      }
    >
      <div className={b()}>
        <PinInput 
          length={6}
          size='xl'
          type="numeric" 
          value={code}
          onUpdate={handleOnChangeCode}
        />
      </div>
    </FormModal>
  );
};
