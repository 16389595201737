import React from 'react';
import block from 'bem-cn-lite';
import {Skeleton} from '@gravity-ui/uikit';
import { IAct } from '../../../../store/api/storage/types/models';

import './ActList.scss';
import { CircularProgress, Stack } from '@mui/material';

const b = block('acts-list-container');

interface ActListProps {
  acts: IAct[];
  isLoading?: boolean;
  isScrollLoading?: boolean;
  renderItem: (act: IAct) => React.ReactNode;
  renderEmptyList: () => React.ReactNode;
  onScroll: () => void;
}

export const ActList: React.FC<ActListProps> = ({
  acts,
  isLoading,
  isScrollLoading,
  onScroll,
  renderItem,
  renderEmptyList
}) => {

  const [listLastScrollTop, setListLastScrollTop] = React.useState<number>(0);
  
  const handlerOnScrollList = async (event: any) => {
    const scrollTop = event.target.scrollTop;
    const scrollTopMax = event.target.scrollHeight - event.target.clientHeight;
    
    if (scrollTop > listLastScrollTop) {
      setListLastScrollTop(scrollTop);
    } else if (scrollTop === 0) {
			setListLastScrollTop(scrollTop);
		} else {
      return;
    }

    if (scrollTopMax === scrollTop) {
      onScroll();
    }
  }

  return (
    <div id='act-list-container' className={b()} onScroll={handlerOnScrollList}>
      {isLoading
        ? <Skeleton className={b('act-skeleton')} />
        : null
      }
      
      {acts.length === 0 ? (
        renderEmptyList()
      ) : (
        <div className={b('acts-list')}>
          {acts.map((act) => renderItem(act))}
         
          {isScrollLoading
            ? <Stack width='100%' alignItems='center'>
                <CircularProgress />
              </Stack>
            : null
          }
        </div>
      )}
    </div>
  );
};