import React from 'react';
import block from 'bem-cn-lite';
import './ActCounter.scss';
import {Button, Icon} from '@gravity-ui/uikit';
import {GroupButtons} from '../group-buttons';
import {Xmark} from '@gravity-ui/icons';

const b = block('act-counter');

interface ActCounterProps {
    actIds: string[];
    onClose: () => void;
    onApply: () => void;
    onReject: () => void;
    onAllSelect: () => void;
}

export const ActCounter: React.FC<ActCounterProps> = ({
    actIds,
    onAllSelect,
    onApply,
    onReject,
    onClose,
}) => {
    return (
        <div className={b()}>
            <div className={b('container')}>
                <div className={b('counter-box')}>
                    <div className={b('counter')}>Выбрано {actIds.length} акта</div>
                    <Button
                        view="outlined-contrast"
                        onClick={onAllSelect}
                        style={{
                            marginLeft: 16,
                        }}
                    >
                        Выбрать все
                    </Button>
                </div>
                <GroupButtons align="flex-end" fullWidth={false}>
                    <Button view="outlined-danger" onClick={onReject}>
                        Отклонить
                    </Button>
                    <Button view="outlined-success" onClick={onApply}>
                        Принять
                    </Button>
                    <Button view="outlined-contrast" onClick={onClose}>
                        <Icon data={Xmark} size={16} />
                    </Button>
                </GroupButtons>
            </div>
        </div>
    );
};
